
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonIcon, IonItem, IonLabel, IonList } from '@ionic/vue';
import { personAddOutline, syncOutline, timeOutline, checkmarkCircleOutline } from 'ionicons/icons';

export default  {
  name: 'SyncLeads',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonIcon, IonItem, IonLabel, IonList },
  setup () {
    return {
      personAddOutline,
      syncOutline,
      timeOutline,
      checkmarkCircleOutline
    }
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    const syncEvent = new CustomEvent('sync');
    window.dispatchEvent(syncEvent);
    next();
  },
  computed: {
    list () {
      return (this as any).$store.state.leadsAwaitingInsertionQueue
    }
  }
}
